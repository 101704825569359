import React from 'react';
import Layout from '../components/Layout';
import Head from 'next/head';
import { Detector } from 'react-detect-offline';
import dynamic from 'next/dynamic';
import {
  ApolloProvider,
} from '@apollo/client';
import { ModalProvider } from 'react-modal-hook';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import OfflineScreen from 'components/OfflineScreen/OfflineScreen';
import AppStoreProvider from 'providers/AppStoreProvider';
import { Amplify } from 'aws-amplify';
import { RecoilRoot } from 'recoil';
import { useIdle } from 'react-use'

import 'styles/app.css';
import { useGlobalOnScanEventListener } from 'core/components/Scanner/useOnScanEventListener';
import apolloClient from 'core/services/apolloClient';
import { SelectedOrderProvider } from 'core/components/SelectedOrderData';
import { SubscriptionProvider } from 'core/components/SelectedOrderData/dataSubscription';
import { AuthState } from 'core/components/AuthState';
import TabTitleCounter from 'core/components/TabTitleCounter';
import AuthService from 'services/auth'

Amplify.configure({
  identityPoolId: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
  region: process.env.NEXT_PUBLIC_COGNITO_REGION,
  userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
  userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  Auth: {
    Cognito: {
      userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
      identityPoolId: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID
    }
  }
});


const ScannersProvider = dynamic(
  () => import('core/components/Scanner/ScannersProvider'),
  {
    ssr: false
  }
);

const AppVersionChecker = () => {
  React.useEffect(() => {
    const checkVersion = () => {
      if (process.env.NODE_ENV !== 'development') {
        fetch('/version.json').then(res => res.json()).then(data => {
          console.log('---version checker---', data.version, process.env.NEXT_PUBLIC_BUILD_VERSION);
          if (data.version !== process.env.NEXT_PUBLIC_BUILD_VERSION) {
            if (confirm('New version of APP available, do you want update?')) {
              location.reload();
            }
          }
        }).catch(err => {
          console.log('---version check failed---', err);
        })
      }
    }
    const timer = setInterval(() => {
      checkVersion()
    }, 1000 * 60 * 10);
    checkVersion();
    return () => {
      clearInterval(timer);
    }
  }, []);
  return null;
}


const GlobalScanner = () => {
  import('core/utils/onscanjs.js').then((onScan) => {
    if (!onScan.isAttachedTo(document)) {
      window.onScan = onScan;
      onScan.attachTo(document, {
        timeBeforeScanTest: 15 * 1000,
        ignoreIfFocusOn: 'input'
      });
    }
  });
  useGlobalOnScanEventListener();
  return null;
};

const App = ({ Component, pageProps }) => {
  useAutologout()
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
      </Head>
      <RecoilRoot>
        <AppStoreProvider>
          <Layout>
            <Detector polling={{ enabled: true, url: 'https://www.lifechef.com/robots.txt' }} render={({ online }) => (
              <>
                {
                  !online && (
                    <OfflineScreen>
                      Internet connection error. <br />
                      Waiting for internet connection...
                    </OfflineScreen>
                  )
                }
                {
                  online && (
                    <ApolloProvider client={apolloClient}>
                      <ModalProvider>
                        <SelectedOrderProvider>
                          <SubscriptionProvider>
                            <ScannersProvider>
                              <AuthState />
                              <AppVersionChecker />
                              <GlobalScanner />
                              <TabTitleCounter />
                              <Component {...pageProps} />
                            </ScannersProvider>
                          </SubscriptionProvider>
                        </SelectedOrderProvider>
                      </ModalProvider>
                      <ToastContainer />
                    </ApolloProvider>
                  )
                }
              </>
            )} />
            {/* <Offline>
              <OfflineScreen>
                Internet connection error. <br />
                Waiting for internet connection...
              </OfflineScreen>
            </Offline>
            <Online>
              <ApolloProvider client={apolloClient}>
                <ModalProvider>
                  <SelectedOrderProvider>
                    <SubscriptionProvider>
                      <ScannersProvider>
                        <AuthState />
                        <AppVersionChecker />
                        <GlobalScanner />
                        <TabTitleCounter />
                        <Component {...pageProps} />
                      </ScannersProvider>
                    </SubscriptionProvider>
                  </SelectedOrderProvider>
                </ModalProvider>
                <ToastContainer />
              </ApolloProvider>
            </Online> */}
          </Layout>
        </AppStoreProvider>
      </RecoilRoot>
    </>
  );
};
export default App;

function useAutologout() {
  const isIdle = useIdle(15 * 60 * 1000)

  React.useEffect(() => {
    if (isIdle) {
      AuthService.getCurrentUser().then(user => {
        if (user) {
          return AuthService.logout()
        }
      }).catch(() => { })
    }
  }, [isIdle])
}
